@tailwind base;
@tailwind components;
@tailwind utilities;


.hexagon {
    width: 220px;
    height: 220px;
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

